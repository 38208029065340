<template>
  <div>
    <v-dialog v-model="dialogVisible" width="fit-content">
      <v-card class="pdp-modal-container">
        <div class="header-modal">
          <div class="title-modal">
            <div class="headline-l">
              ลงทะเบียนแสดงความสนใจ
            </div>
          </div>
          <button class="btn-close" @click="closeModal">
            <v-icon class="icon-close">
              mdi-close
            </v-icon>
          </button>
        </div>

        <div class="pdp-modal-content">
          <PreRegisterForm
            ref="PreRegisterForm"
            is-modal
            @submit="closeModal"
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'PreRegisterModal',
  props: {
    htmlContent: {
      type: String,
      default: '',
    },
  },
  components: {
    PreRegisterForm: () =>
      import('@/components/bnn/PreRegisterForm.vue'),
  },
  computed: {},
  data() {
    return {
      dialogVisible: false,
    }
  },
  methods: {
    openModal() {
      this.dialogVisible = true
    },
    closeModal() {
      this.dialogVisible = false
      this.$refs.PreRegisterForm.resetValidation()
    },
  },
}
</script>

<style lang="stylus" scoped>
::v-deep .v-dialog {
    border-radius 18px
    border: 2px solid $color-bnn
    background-color: #fff

    overflow-x hidden
    max-height 100dvh
    // max-height 100dvh
    // overflow-y auto
    margin 20px 0 0

    @media screen and (min-width: 768px) {
        margin 0
    }

    @media screen and (min-width: 1024px) {
        margin 0
    }
}

.pdp-modal-container {
    margin 0 auto !important
    width 100%
    min-height fit-content
    max-width 560px
    min-width 100%
    position relative

    display flex
    flex-direction column

    padding: 24px
    //border-radius: 18px

    @media screen and (min-width: 1024px) {
        max-width 768px
    }

}

.pdp-modal-content {
    width 100%
    min-height fit-content
    height: fit-content
}

.header-modal {
    display: flex
    justify-content: space-between
    margin-bottom: 16px
}

.btn-close {
    width: 40px;
    height: 40px;

    background: #e8e8e8;
    border-radius: 50% !important;

    z-index 10

    &:hover {
        .icon-close {
            color: rgba(0,0,0,0.72)
        }
    }

    &:focus {
        outline: .125rem solid #0071e3;
        outline-offset: .125rem;
        outline-offset: .125rem;
    }

    .icon-close {
        color: rgba(18,18,18,0.56)
    }
}
</style>
